const dev = {
  API_ENDPOINT_URL: 'https://ecosync-oem.airi-sense.co.in/backend/api'
};

const prod = {
  API_ENDPOINT_URL: 'https://ecosync-oem.airi-sense.co.in/backend/api'
};

const test = {
  API_ENDPOINT_URL: 'https://ecosync-oem.airi-sense.co.in/backend/api'
};

//http://192.168.0.9:8000/api
const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
