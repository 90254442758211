import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const machineDataListAll = createAsyncThunk('machinedata/list',async (data, { rejectWithValue }) => {
	
	
	try {
		const response = await clientService.getMachineData(data)
		
        return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const machineDataListSlice = createSlice({
	name: 'machineData',
	initialState,
	reducers: {
			
		machineDataList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(machineDataListAll.pending, (state) => {
                
				state.loading = false
			})
			.addCase(machineDataListAll.fulfilled, (state, action) => {
				
                state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	machineDataList,
    
    
} = machineDataListSlice.actions

export default machineDataListSlice.reducer