import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/reset-password`,
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.department',
        path: `${APP_PREFIX_PATH}/dashboards/department`,
        component: React.lazy(() => import('views/app-views/dashboards/department')),
    },
    {
        key: 'dashboard.designation',
        path: `${APP_PREFIX_PATH}/dashboards/designation`,
        component: React.lazy(() => import('views/app-views/dashboards/designation')),
    },
    {
        key: 'dashboard.frequency',
        path: `${APP_PREFIX_PATH}/dashboards/frequency`,
        component: React.lazy(() => import('views/app-views/dashboards/frequency')),
    },
    {
        key: 'dashboard.client',
        path: `${APP_PREFIX_PATH}/dashboards/client`,
        component: React.lazy(() => import('views/app-views/dashboards/client')),
    },
    {
        key: 'dashboard.addclient',
        path: `${APP_PREFIX_PATH}/dashboards/client-setting`,
        component: React.lazy(() => import('views/app-views/dashboards/client/clientadd')),
    },
    {
        key: 'dashboard.clientedit',
        path: `${APP_PREFIX_PATH}/dashboards/client-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/client/clientedit')),
    },
    {
        key: 'dashboard.company',
        path: `${APP_PREFIX_PATH}/dashboards/company`,
        component: React.lazy(() => import('views/app-views/dashboards/company')),
    },
    {
        key: 'dashboard.client.companyadd',
        path: `${APP_PREFIX_PATH}/dashboards/company-add`,
        component: React.lazy(() => import('views/app-views/dashboards/company/companyadd')),
    },
    {
        key: 'dashboard.client.companyedit',
        path: `${APP_PREFIX_PATH}/dashboards/company-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/company/companyedit')),
    },
    {
        key: 'dashboard.section',
        path: `${APP_PREFIX_PATH}/dashboards/section/:plantid`,
        component: React.lazy(() => import('views/app-views/dashboards/section')),
    },
    {
        key: 'dashboard.sectionadd',
        path: `${APP_PREFIX_PATH}/dashboards/section-add/:plantid`,
        component: React.lazy(() => import('views/app-views/dashboards/section/sectionadd')),
    },
    {
        key: 'dashboard.sectionedit',
        path: `${APP_PREFIX_PATH}/dashboards/section-edit/:plantid/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/section/sectionedit')),
    },
    {
        key: 'dashboard.subsection',
        path: `${APP_PREFIX_PATH}/dashboards/subsection/:plantid/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/section/subsection')),
    },
    {
        key: 'dashboard.plant',
        path: `${APP_PREFIX_PATH}/dashboards/plant`,
        component: React.lazy(() => import('views/app-views/dashboards/plant')),
    },
    {
        key: 'dashboard.client.plantadd',
        path: `${APP_PREFIX_PATH}/dashboards/plant-add`,
        component: React.lazy(() => import('views/app-views/dashboards/plant/plantadd')),
    },
    {
        key: 'dashboard.client.plantedit',
        path: `${APP_PREFIX_PATH}/dashboards/plant-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/plant/plantedit')),
    },
    {
        key: 'dashboard.employee',
        path: `${APP_PREFIX_PATH}/dashboards/employee`,
        component: React.lazy(() => import('views/app-views/dashboards/employee')),
    },
    {
        key: 'dashboard.client.employeeadd',
        path: `${APP_PREFIX_PATH}/dashboards/employee-add`,
        component: React.lazy(() => import('views/app-views/dashboards/employee/employeeadd')),
    },
    {
        key: 'dashboard.client.employeeedit',
        path: `${APP_PREFIX_PATH}/dashboards/employee-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/employee/employeeedit')),
    },
    {
        key: 'dashboard.equipmentcategory',
        path: `${APP_PREFIX_PATH}/dashboards/category`,
        component: React.lazy(() => import('views/app-views/dashboards/category')),
    },
    {
        key: 'dashboard.client.categoryadd',
        path: `${APP_PREFIX_PATH}/dashboards/category-add`,
        component: React.lazy(() => import('views/app-views/dashboards/category/categoryadd')),
    },
    {
        key: 'dashboard.client.categoryedit',
        path: `${APP_PREFIX_PATH}/dashboards/category-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/category/categoryedit')),
    },
    {
        key: 'dashboard.subcategory',
        path: `${APP_PREFIX_PATH}/dashboards/subcategory/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/category/subcategory')),
    },
    {
        key: 'dashboard.equipment',
        path: `${APP_PREFIX_PATH}/dashboards/equipment`,
        component: React.lazy(() => import('views/app-views/dashboards/equipment')),
    },
    {
        key: 'dashboard.process',
        path: `${APP_PREFIX_PATH}/dashboards/process`,
        component: React.lazy(() => import('views/app-views/dashboards/process')),
    },
    {
        key: 'dashboard.plantequipment',
        path: `${APP_PREFIX_PATH}/dashboards/plantequipment`,
        component: React.lazy(() => import('views/app-views/dashboards/plantequipment')),
    },
    {
        key: 'dashboard.client.plantequipmentadd',
        path: `${APP_PREFIX_PATH}/dashboards/plantequipment-add`,
        component: React.lazy(() => import('views/app-views/dashboards/plantequipment/plantequipmentadd')),
    },
    {
        key: 'dashboard.client.plantequipmentedit',
        path: `${APP_PREFIX_PATH}/dashboards/plantequipment-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/plantequipment/plantequipmentedit')),
    },
    {
        key: 'dashboard.productionsku',
        path: `${APP_PREFIX_PATH}/dashboards/productionsku`,
        component: React.lazy(() => import('views/app-views/dashboards/productionsku')),
    },,
    {
        key: 'dashboard.client.productionskuadd',
        path: `${APP_PREFIX_PATH}/dashboards/productionsku-add`,
        component: React.lazy(() => import('views/app-views/dashboards/productionsku/prodskuadd')),
    },
    {
        key: 'dashboard.client.productionskuedit',
        path: `${APP_PREFIX_PATH}/dashboards/productionsku-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/productionsku/prodskuedit')),
    },
   
    {
        key: 'dashboard.machinedata',
        path: `${APP_PREFIX_PATH}/dashboards/machinedata`,
        component: React.lazy(() => import('views/app-views/dashboards/machinedata')),
    },
    {
        key: 'dashboard.productionsetup',
        path: `${APP_PREFIX_PATH}/dashboards/productionsetup`,
        component: React.lazy(() => import('views/app-views/dashboards/productionsetup')),
    },
    {
        key: 'dashboard.client.productionsetupadd',
        path: `${APP_PREFIX_PATH}/dashboards/productionsetup-add`,
        component: React.lazy(() => import('views/app-views/dashboards/productionsetup/productionsetupadd')),
    },
    {
        key: 'dashboard.client.productionsetupedit',
        path: `${APP_PREFIX_PATH}/dashboards/productionsetup-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/productionsetup/productionsetupedit')),
    },
]