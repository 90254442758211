import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import company from './slices/companySlice'
import section from './slices/sectionSlice'
import plant from './slices/plantSlice'
import master from './slices/masterSlice'
import employee from './slices/employeeSlice'
import category from './slices/categorySlice'
import equipment from './slices/equipmentSlice'
import process from './slices/processSlice'
import plantequipment from './slices/plantEquipmentSlice'
import pmchecklist from './slices/productionsetupSlice'
import checklist from './slices/checklistSlice'
import breakdown from './slices/breakdownSlice'
import breakdownlog from './slices/breakdownlogSlice'
import rcadocument from './slices/rcaDocumentSlice'
import productionsku from './slices/productionskuSlice'
import productionsetup from './slices/productionsetupSlice'
import machineData from './slices/machineDataSlice'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        company,
        section,
        plant,
        master,
        employee,
        category,
        equipment,
        process,
        plantequipment,
        productionsetup,
        checklist,
        breakdown,
        breakdownlog,
        rcadocument,
        productionsku,
        machineData,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
